var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.length > 0)?_c('div',{staticClass:"bottom"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideSelector),expression:"hideSelector"}],staticClass:"count",on:{"click":_vm.toggleSelector}},[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),(_vm.selectorIsVisible)?_c('div',{staticClass:"selector"},_vm._l((_vm.items),function(item){return _c('div',{key:item,staticClass:"selector-item",on:{"click":function($event){return _vm.changeItemsPerPage(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"pagination"},[_c('ul',[(_vm.currentPage !== 1)?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('changeCurrentPage', _vm.currentPage - 1)}}},[_c('svg',{attrs:{"width":"6","height":"12","viewBox":"0 0 6 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.47021 11.4461L1.47021 5.94614L5.47021 0.446136","stroke":"black"}})])])]):_vm._e(),_vm._l((_vm.numberOfButtons),function(item){return _c('div',{key:item},[(
            (item - _vm.currentPage > -3 && item - _vm.currentPage < 3) ||
            item === 1 ||
            item === _vm.numberOfButtons
          )?_c('div',{staticStyle:{"display":"flex","gap":"6px"}},[(
              _vm.numberOfButtons - _vm.currentPage > 3 &&
              _vm.numberOfButtons > 4 &&
              item === _vm.numberOfButtons
            )?_c('div',{staticStyle:{"margin-right":"1rem","display":"flex","align-items":"end","cursor":"default"}},[_vm._v(" ... ")]):_vm._e(),_c('li',[_c('a',{class:{ active: item === _vm.currentPage },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('changeCurrentPage', item)}}},[_vm._v(_vm._s(item))])]),(_vm.currentPage > 3 && _vm.numberOfButtons > 7 && item === 1)?_c('div',{staticStyle:{"margin-right":"1rem","display":"flex","align-items":"end","cursor":"default"}},[_vm._v(" ... ")]):_vm._e()]):_vm._e()])}),(_vm.currentPage !== _vm.numberOfButtons)?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('changeCurrentPage', _vm.currentPage + 1)}}},[_c('svg',{attrs:{"width":"6","height":"12","viewBox":"0 0 6 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.470825 0.445938L4.47083 5.94594L0.470827 11.4459","stroke":"black"}})])])]):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }