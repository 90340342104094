<template>
  <div class="column" :class="'item_' + classSuffix">
    <div :class="{ phone: data }">{{ data }}</div>
    <span v-if="data" class="tooltip">{{ data }}</span
    ><span class="blue_arrow"
      ><svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1C7 1 4.91667 3 4 4L1 1"
          stroke="white"
          stroke-width="1.5"
        ></path></svg
    ></span>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemPhone",
  props: ["data", "classSuffix"],
};
</script>
