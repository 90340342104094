<template>
  <div class="column" :class="'item_' + classSuffix">
    <div style="cursor: pointer" @click="openDraver">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5909 1.58492C11.7763 1.39948 11.9964 1.25238 12.2387 1.15201C12.481 1.05165 12.7407 1 13.003 1C13.2652 1 13.5249 1.05165 13.7672 1.15201C14.0095 1.25238 14.2296 1.39948 14.4151 1.58492C14.6005 1.77036 14.7476 1.99051 14.848 2.2328C14.9483 2.47509 15 2.73478 15 2.99703C15 3.25928 14.9483 3.51897 14.848 3.76126C14.7476 4.00355 14.6005 4.2237 14.4151 4.40914L4.88331 13.9409L1 15L2.05909 11.1167L11.5909 1.58492Z"
          stroke="#808080"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
    <Drawer :showDrawer="showDrawer" :close="closeDrawer" :maxWidth="888">
      <div class="modal_title_top">Редактирование данных</div>
      <div class="form_redact four_laps">
        <RedactField
          v-for="(field, index) in fields"
          :field="field"
          :error="errors[field.name]"
          v-model="field.value"
          :key="'field_' + index"
        />
        <Button @click="saveCard" text="Отправить"></Button>
<!--        <Button @click="deleteCard" text="Удалить"></Button>-->
      </div>
    </Drawer>
  </div>
</template>

<script>
import Drawer from "@/components/4laps/Drawer.vue";
import { logDebug } from "@/helpers/logHelper";
// import {FormHelper} from "@/helpers/formHelper";
import RedactField from "@/components/UI/RedactField.vue";
import { validate } from "@/helpers/formValidateHelper";
import { parse } from "libphonenumber-js";
import Button from "@/components/UI/Button.vue";
import {deletePets, putPets} from "@/helpers/4laps/dataHelpers";
import {bus} from "@/bus";

export default {
  name: "UkTableRowItemEdit",
  // SelectMultiple, FormInputPassword,
  components: { RedactField, Drawer, Button },
  props: ["data", "record", "classSuffix"],
  data() {
    return {
      showDrawer: false,
      errors: {},
      loader: true,
      fields: [
        {
          type: "string",
          title: "Кличка",
          name: "pet_name",
          placeholder: "Введите кличку животного",
          value: "",
          min: 2,
        },
        {
          type: "string",
          title: "Имя хозяина",
          name: "owner_name",
          placeholder: "Введите имя хозяина",
          value: "",
          min: 2,
        },
        {
          type: "phone",
          title: "Телефон",
          name: "phone",
          placeholder: "Введите номер телефона",
          value: "",
        },
        {
          type: "phone",
          title: "Дополнительный номер",
          name: "phone_2",
          placeholder: "Введите номер телефона",
          value: "",
        },
        {
          type: "string",
          title: "Дополнительный контакт: телефон",
          name: "dop_name",
          placeholder: "Введите имя",
          value: "",
        },
        {
          type: "string",
          title: "СМС код",
          name: "sms_code",
          placeholder: "Введите смс для подтверждения телефона",
          value: "",
          min: 4,
          max: 4,
        },
      ],
    };
  },
  mounted() {
    this.fields.map((field) => {
      if (this.record[field.name]) {
        // todo need to set in helpers check
        if (field.type === "phone") {
          logDebug(this.record[field.name]);

          if (this.record[field.name].slice(0, 2) === "+7") {
            field.value = this.record[field.name].slice(
              2,
              this.record[field.name].length
            );
          } else if (this.record[field.name].slice(0, 1) === "8") {
            field.value = this.record[field.name].slice(
              1,
              this.record[field.name].length
            );
          } else {
            field.value = this.record[field.name];
          }
        } else {
          field.value = this.record[field.name];
        }
      }
      // if (field.value === null || field.value === undefined) {
      //   if (field.type === 'string') {
      //     field.value = ''
      //   }
      // }
      return field;
    });
  },
  methods: {
    openDraver() {
      logDebug(this.record);
      this.showDrawer = true;
    },
    closeDrawer() {
      this.showDrawer = false;
    },
    deleteCard() {
      deletePets(this.record.qr,(response) => {
        logDebug(response.data.data);
        bus.$emit("ListOfObjects update after delete", response.data.data);
        this.closeDrawer();
      });
    },
    saveCard() {
      const [err, isValid] = validate(this.errors, this.fields);
      this.errors = err;
      if (isValid) {
        const data = {};
        this.fields.forEach((item) => {
          if (item.name === "phone") {
            const parsePhone = parse(item.value, "RU");
            if (parsePhone.phone) {
              data[item.name] = parsePhone.phone;
            } else {
              data[item.name] = item.value;
            }
          } else if (item.name === "phone_2") {
            if (item.value) {
              data[item.name] = item.value;
            }
          } else if (item.name === "dop_name") {
            if (item.value) {
              data[item.name] = item.value;
            }
          } else {
            data[item.name] = item.value;
          }
          // data[item.name] = item.value;
        });
        logDebug(data);
        putPets(this.record.qr, data,(response) => {
          logDebug(response.data.data);
          bus.$emit("ListOfObjects update after save", response.data.data);
          this.closeDrawer();
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.form_redact {
  padding: 70px 30px 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 20px;
  &.four_laps {
  }
}
</style>
