<template>
  <div id="add_object" class="white-popup popup_add_object mfp-with-anim">
    <div class="top-wrapper">
      <div class="top">
        {{ this.cardData.key ? "Редактировать qr-code" : "Добавить qr-code" }}
      </div>
    </div>
      <input
        @click.prevent="saveQr"
        type="submit"
        value="Сохранить"
        :disabled="!isTouched()"
      />
  </div>
</template>

<script>


export default {
  name: "EditObject",
  props: ["cardData", "isTouched"],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    saveQr() {}
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.top-wrapper {
  position: relative;
}

.delete-object-wrapper {
  position: absolute;
  top: 0;
  right: 30px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #ff5151;
  cursor: pointer;
}

.delete-object-text {
  margin-right: 5px;
}

.url-input-wrapper {
  display: flex;
  justify-content: flex-start;
}

.url-input-wrapper .url-start {
  background: #f8f8f8;
  border-radius: 3px;
  height: 4.8rem;
  padding: 0;
  padding-left: 1.2rem;
  border: none;
  font-size: 1.4rem;
  color: #00000088;
  display: flex;
  align-items: center;
}

#add_object .url-input-wrapper .url-end {
  padding-left: 0;
  width: 100%;
}

#add_object .url-input-wrapper .url-end:invalid {
  box-shadow: none;
}

.url-warning {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff3333;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.url-warning-text {
  margin-left: 5px;
}

.qr-code-wrapper {
  display: flex;
}

.popup_container {
  display: flex;
  justify-content: space-between;
}

.review_example_wrapper {
  width: 175px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-card-logo-wrapper {
  min-height: 125px;
  margin: 10px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .review-card-logo {
    max-height: 125px;
    max-width: 100%;
  }
}

.review-example-url {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-example-text {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin: 20px 0;
}

.text-qr {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.review-card-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-card-usekitlogo {
  margin: 15px;
}

#add_object .qr_links {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  justify-content: flex-start;
  align-content: flex-end;
  flex-direction: column;
}

#add_object .qr_links .download {
  margin-top: 25px;
}

@media (max-width: 760px) {
  .popup_bottom_right {
    margin-top: 30px;
  }
}

@media print {
  @page {
    size: auto;
    margin: 3mm;
  }

  .review_example {
    display: flex;
    width: 394px;
    height: 174px;
    justify-content: space-between;
    background-color: rgba(24, 144, 255, 0.05) !important;
    -webkit-print-color-adjust: exact;
    padding: 10px;
    font-family: Roboto, sans-serif;
  }

  .review_example .review_example_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .review_example .review_example_left .text_url {
    font-size: 10px;
  }

  .review_example .review_example_left .text {
    font-size: 18px;
    text-align: left;
  }
}
</style>
