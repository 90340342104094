// В данном плагине определены только переиспользуемые методы
// необходимые для геолокации и пр.

// Метод для получения расстояние между двумя точками.
// Метод получает значение широты и долготы двух точек.
// Возвращает расстояние в метрах. Алгоритмы:
// https://www.kobzarev.com/programming/calculation-of-distances-between-cities-on-their-coordinates/#formuly
export const getDistanceByPoints = ({ lt1, lg1, lt2, lg2 }) => {
    // Переводим значение координат в радианы
    const lat1 = lt1 * Math.PI / 180,
        lat2 = lt2 * Math.PI / 180,
        long1 = lg1 * Math.PI / 180,
        long2 = lg2 * Math.PI / 180;

    // Косинусы и синусы широты и разницы долгыт
    const cl1 = Math.cos(lat1),
        cl2 = Math.cos(lat2),
        sl1 = Math.sin(lat1),
        sl2 = Math.sin(lat2),
        delta = long2 - long1,
        cdelta = Math.cos(delta),
        sdelta = Math.sin(delta);

    // Вычисляем длинну большого круга
    const y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
    const x = sl1 * sl2 + cl1 * cl2 * cdelta;

    // Вычисляем расстояние по формуле
    // 6372795 - радиус Земли
    const ad = Math.atan2(y, x),
        dist = ad * 6372795;

    // Размерность возвращаемого расстояния - метры
    return dist;
};

// Метод получения центра координат.
// Возращает число
export const getCoordsCenter = (coords, type) => {
    // Если координаты не переданы, возвращаем 0
    if(!coords.length) return 0
    // Берем самую первую координату из массива
    // Получаем положительную часть числа.
    let starts = Math.abs(coords[0][type]);
    const centerCoordsLt = [];

    // Создаем новый массив который будет состоять из координат,
    // составленных от самой первой точки до последней.
    // Шаг при обходе 0.00005. Первоначальное значение имеет большое кол-во знаков
    // после запятой, поэтому было выбрано это число.
    // Можно добавить любое, но чем меньше будет число, тем точнее можно определить центр.
    while(starts < coords[coords.length - 1][type]){
        if(starts + 0.00005 >= coords[coords.length - 1][type]){
            centerCoordsLt.push(coords[coords.length - 1][type])
        };
        starts += 0.00005;
        centerCoordsLt.push(starts);
    }
    // Вовзращаем центральную точку массива
    return centerCoordsLt[Math.ceil(centerCoordsLt.length / 2) - 1]
};

// Функция посредник. Возвращает расстояние по X и Y координате (в метрах),
// для всего пути пройденного ребенком.
// На вход получает массив координат (объектов со свойствами latitude и longitude).
// Первый и последний элемент которого передаются в getDistanceByPoints().
export const getDistanceForCoords = (coords) => {

    // Проверка сделана для того, чтобы исключить ошибку
    // Ошибка возникает из за того что если нет элементов в массиве,
    // а мы обращаемся к первому элементу которого нет,
    // и его свойству latitude или longitude, то это дает нам undefined
    if(!coords.length) return 0;

    // Передаем эти координаты в метод который
    // нам возращает расстояние между координатами в метрах.
    return getDistanceByPoints({
        lt1: coords[0].latitude,
        lg1: coords[0].longitude,
        lt2: coords[coords.length - 1].latitude,
        lg2: coords[coords.length - 1].longitude
    });
};

// Метод сортировки массива географических координат
// Сортировка применяется для latitude/longitude
export const sortsCoords = (coords, type) => {
    // Сортировка координат, для того
    // чтобы можно было получить минимальное и максимальное значение
    const res = [...coords];
    return res.sort((a, b) => {
        return a[type] - b[type]
    });
};

// Метод группировки массива географических координат
// по временным промежтукам
export const groupCoordsByTime = (coords) => {
    // Максимальное время между замерами
    // для объединенияточек по группам (в секундах)
    const interval = 5*60*1000;
    // Сортировка координат, по времени
    // чтобы они были расставлены в хронологическом порядке
    const res = [...coords];
    // Метод одолжен из ответа
    // https://stackoverflow.com/questions/57355613/javascript-group-on-an-array-of-objects-with-timestamp-range-difference
    return res.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .reduce((r, o, i, { [i - 1]: last }) => {
            if (!last || new Date(o.timestamp) - new Date(last.timestamp) > interval) {
                r.push([o]);
            } else {
                r[r.length - 1].push(o);
            }
            return r;
        }, []);
};

// Метод получения массива с длиннами пар всех координат.
// Фактически возвращает матрицу, где индексы это номера точек, а
// значение это расстояние между ними.
export const getCordsPairsLength = (coords) => {
    // Создаем статичную копию массива координат
    const points = [...coords];
    // Новый итоговый массив
    // Подробнее о создании массива https://stackoverflow.com/questions/966225/how-can-i-create-a-two-dimensional-array-in-javascript
    let response = Array.from(Array(points.length), () => new Array(points.length));
    // Находим длину каждой точки с каждой
    for (let frontPointIndex = 0; frontPointIndex < points.length; frontPointIndex++) {
        for (let backPointIndex = 0; backPointIndex < points.length; backPointIndex++) {
            // Округляем, уменьшая точность (так как это всеравно метры)
            // Сделано из-за погрешностей в вычислениях JS.
            response[frontPointIndex][backPointIndex] = parseFloat(getDistanceByPoints({
                lt1: points[frontPointIndex].latitude,
                lg1: points[frontPointIndex].longitude,
                lt2: points[backPointIndex].latitude,
                lg2: points[backPointIndex].longitude
            }).toFixed(4));
        }
    };
    // Возвращаем результатирующую матрицу
    return response;
};

// Метод кластеризации точек. Он определяет для каждой точки
// все точки, расстояние до которых меньше чем E.
export const clasteringCoords = (matrix, e = 600) => {
    // Кол-во опорообразующих точек
    // если оно равно 0, то возвращаем
    // пустой массив, так как функция возвращает массив
    if(!matrix.length) return [];
    const coordsCount = matrix.length;
    // Массив одномерных объектов кластеров
    let clasters = [];

    // Обходим кажду строку матрицы
    // x - нумеруем строки
    // y - нумеруем столбцы

    // Цикл по строки
    if(coordsCount){
        for (let x = 0; x < coordsCount; x++) {
            // Локальный кластер для каждой итерации
            let claster = [];

            // Цикл по столбцы
            for (let y = 0; y < coordsCount; y++) {
                // Если длинна меньше чем ε
                // То точка является потенциальной
                // для входа в кластер.
                if(matrix[x][y] < e){
                    // Флаг прерывания, логическая
                    // переменная от которой зависит
                    // войдет ли точка в кластер.
                    // FALSE - точка не войдет.
                    // TRUE - точка войдет в кластер.
                    let breakpoint = true;

                    // Сравниваем точку со всеми строчками столбца,
                    // кроме нулевых значений (элементров главной матрицы).
                    // Определяем элементы ближе, чем найденная точка.
                    for (let i = 0; i < coordsCount; i++) {
                        if(
                            matrix[y][i] != 0 &&
                            matrix[x][y] > matrix[y][i] &&
                            matrix[i][x] > e
                        ){
                            // Прерываем алгоритм
                            breakpoint = false;
                            break;
                        }
                    }

                    // Если нет флага прерывания, то элемент самый
                    // наименьший добавляем его в кластер.
                    if(breakpoint){
                        // Проверяем есть ли это значение уже в каком-либо
                        // из созданных кластеров
                        let isClastered = false;
                        clasters.forEach(clasterItem => {
                            clasterItem.forEach(clasteredElement => {
                                if(clasteredElement == y){
                                    isClastered = true;
                                }
                            });
                        });
                        // Добавляем в массив, только
                        // не кластеризованные элементы
                        if(!isClastered){ claster.push(y); }
                    }
                }
            }
            // Заканчиваем добавление в первый кластер
            // Переходим ко второму. Пушим в кластер только
            // не пустые массивы.
            if(claster.length){ clasters.push(claster); }
        }
    }


    // Возвращаем кластеры
    return clasters;
};

// Метод получения объектов с кластерами по ключу.
// matrix - матрица расстояний
// coordsList - массив координат для кластеризации
// dimensions - коэффициенты для различных карт
export const clasteringCoordsForAllZoom = (matrix, coordsList, dimensions) => {
    // Данная функция возвращает объект с кластерами,
    // в котором в качестве ключа используется zoom карты,
    // а в качестве значения массив с объектами, в которых:
    // - center - это координаты центра кластера
    // - radius - радиус кластера, который сравнивается с минимальным радиусом на том или ином zoom
    // - count - количество точек в данном кластере
    // - clasterPoints - это исходный массив координат который нам возвращает back
    const clastersList = dimensions.reduce((clastersMap, clasterItem) => {
        const clasters = clasteringCoords(matrix, clasterItem.e);
        const coords = [];
        clasters.forEach(claster => {
            // Проверяем массив на количество элементов в кластере
            // Если у нас нет элементов, тогда делаем return
            // Сделано для предотвращения ошибок
            if(!claster.length) return;
            // Генерируем массив координат на основе матрицы расстояний
            let clasterPoints = claster.map(indexCoord => coordsList[indexCoord]);

            const coordItem = {
                center: [
                    parseFloat(clasterPoints[0].latitude),
                    parseFloat(clasterPoints[0].longitude),
                ],
                radius: clasterItem.e,
                count: clasterPoints.length,
                clasterPoints: clasterPoints
            };

            coords.push(coordItem);
        });
        clastersMap[clasterItem.coef] = coords;
        return clastersMap;
    }, {});

    return clastersList;
};

