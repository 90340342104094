<template>
  <div class="field_wrapper">
    <label :for="random" class="field">
      <div class="field_type">{{ field.title }}</div>
      <div v-if="['string', 'link', 'email', 'video'].includes(field.type)" style="width: 100%;">
        <input
          :id="random"
          v-model="localValue"
          class="input_default"
          :placeholder="field.placeholder"
          :disabled="field.disabled"
          :minlength="field.min"
          :maxlength="field.max"
          type="text"
        />
      </div>
      <div v-else-if="field.type === 'phone'" style="width: 100%;">
        <input
          :id="random"
          v-model="localValue"
          class="input_default"
          :placeholder="field[title]"
          :disabled="field.disabled"
          :minlength="field.min"
          :maxlength="field.max"
          v-mask="'+7 (###) ###-##-##'"
          type="text"
        />
      </div>
    </label>
    <div class="field__validation_error">
      <div>{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Array, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  name: "RedactField",
  data() {
    return {
      errorMessage: "",
      loader: false,
      random: 0,
    };
  },
  computed: {
    title() {
      return `title`;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  watch: {
    error(val) {
      this.errorMessage = val;
    },
  },
  mounted() {
    this.random = Math.floor(Math.random() * 10000);
  },
  methods: {},
};
</script>

<style scoped lang="scss">
// todo need fix
@import "../../assets/styles/fourlaps/typography";
.field {
  height: 60px;
  width: 320px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--use-color-gray-200);

  &_type {
    @include body-6;
    color: var(--use-color-gray-900);
  }
  &_text {
    @include body-5;
    color: var(--use-color-gray-900);
  }

  &:focus-within {
    border: 1px solid var(--use-color-primary-500);
  }
}

.field__validation_error{
  color: var(--use-color-error-700);
}

</style>
