<template>
  <div>
    <div class="head-table">
      <div
        v-for="item in columns"
        :key="item.id"
        class="head-table_item"
        :class="'head_' + item.classSuffix"
      >
        <a
          @click.prevent="headerClicked(item)"
          :class="{
            nothing: item.sorter && item.id !== currentSorter,
            up: direction === 'up' && item.id === currentSorter,
            down: direction === 'down' && item.id === currentSorter,
            filter: isFiltered(item.id),
            'filter-off': !isFiltered(item.id),
          }"
          href=""
          >{{ item.name }}</a>
      </div>
    </div>
    <div v-if="data.length === 0" class="empty-table-info">
      <slot></slot>
    </div>
    <div class="items">
      <UkTableRow
        v-for="item in data"
        :key="item.id"
        :data="item"
        :columns="columns"
      />
    </div>
    <UkTablePagination
      :itemsPerPage="itemsPerPage"
      :length="total"
      :currentPage="currentPage"
      @changeCurrentPage="changeCurrentPageHandler"
      @changeItemsPerPage="changeItemsPerPageHandler"

    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

import { bus } from "@/bus";

import UkTableRow from "@/components/4laps/UkTableRow";
import UkTablePagination from "@/components/4laps/UkTablePagination";

import { getPoints } from "@/helpers/dataHelpers";

export default {
  name: "UkTable",
  props: ["columns", "data", "itemsPerPage", "currentPage", "total"],
  data() {
    return {
      currentSorter: "",
      direction: "",
      itemsPerPageLocal: this.itemsPerPage,
      currentPageLocal: this.currentPage,
      filters: {},
      filteredOptions: [],
      nextPage: null,
    };
  },
  components: {
    UkTableRow,
    UkTablePagination
  },
  computed: {
    computedData() {
      let dataLocal = cloneDeep(this.data);

      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key].key) {
          dataLocal = dataLocal.filter(
            (item) => item[key] && item[key].key === this.filters[key].key
          );
        }
      });

      if (this.currentSorter) {
        const sorter = this.columns.find(
          (item) => item.id === this.currentSorter
        ).sorter;
        if (this.direction === "up") {
          dataLocal.sort((a, b) =>
            sorter(a[this.currentSorter], b[this.currentSorter])
          );
        } else if (this.direction === "down") {
          dataLocal.sort((a, b) =>
            sorter(b[this.currentSorter], a[this.currentSorter])
          );
        }
      }

      return dataLocal;
    }
  },
  created() {
    bus.$on("UkTable go to last page", () => {
      this.nextPage = "last";
    });
    bus.$on("UkTable stay at page", () => {
      this.nextPage = "current";
    });
  },
  methods: {
    changeItemsPerPageHandler(n) {
      this.itemsPerPageLocal = n;
      this.currentPageLocal = 1;
      this.$emit("changeItemsPerPageHandler", n);
    },
    changeCurrentPageHandler(n) {
      this.currentPageLocal = n;
      this.$emit("changeCurrentPageHandler", n);
    },
    setSorter(item) {
      if (item.sorter) {
        if (this.currentSorter === item.id) {
          if (this.direction === "up") {
            this.direction = "down";
          } else if (this.direction === "down") {
            this.direction = "";
            this.currentSorter = "";
          } else if (this.direction === "") {
            this.direction = "up";
          }
        } else {
          this.direction = "up";
          this.currentSorter = item.id;
        }
      }
    },
    setFilter(item) {
      if (!item.filter) return;

      getPoints((response) => {
        this.filteredOptions = response.data.data.map((item) => ({
          key: item.pointId,
          name: item.name,
        }));

        if (!this.filters[item.id]) this.filters[item.id] = {};
        this.filters[item.id].show = !this.filters[item.id].show;
        this.filters = cloneDeep(this.filters); // чтобы вызвать реактивный ререндер
      });
    },
    filterHandler(value, id) {
      if (!this.filters[id]) this.filters[id] = {};
      this.filters[id].show = false;
      if (value !== undefined) this.filters[id].key = value.key;
      this.filters = cloneDeep(this.filters); // чтобы вызвать реактивный ререндер
    },
    headerClicked(item) {
      this.setSorter(item);
      this.setFilter(item);
    },
    isShow(itemID) {
      return Boolean(this.filters[itemID]?.show);
    },
    isFiltered(itemID) {
      return Boolean(this.filters[itemID]?.key);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/fourlaps/index";

.filter-input {
  position: absolute;
  z-index: 100;
  width: 200px;
  margin-top: 8px;
}

.middle-input {
  width: 100%;
  text-align: right;
  padding-top: 6px;
}

.middle-input button {
  margin-left: 6px;
}

.empty-table-info {
  height: 85px;
  border: 1px solid rgba(138, 138, 138, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-table{
  background: var(--use-color-primary-200);
  border-radius: 5px;
  padding: 1.6rem 0;
  display: flex;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin-top: 1.9rem;
  margin-bottom: 1.2rem;
  color: var(--use-color-primary-700);
  min-width: fit-content;
  width: 100%;
}
</style>
