var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"head-table"},_vm._l((_vm.columns),function(item){return _c('div',{key:item.id,staticClass:"head-table_item",class:'head_' + item.classSuffix},[_c('a',{class:{
          nothing: item.sorter && item.id !== _vm.currentSorter,
          up: _vm.direction === 'up' && item.id === _vm.currentSorter,
          down: _vm.direction === 'down' && item.id === _vm.currentSorter,
          filter: _vm.isFiltered(item.id),
          'filter-off': !_vm.isFiltered(item.id),
        },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.headerClicked(item)}}},[_vm._v(_vm._s(item.name))])])}),0),(_vm.data.length === 0)?_c('div',{staticClass:"empty-table-info"},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"items"},_vm._l((_vm.data),function(item){return _c('UkTableRow',{key:item.id,attrs:{"data":item,"columns":_vm.columns}})}),1),_c('UkTablePagination',{attrs:{"itemsPerPage":_vm.itemsPerPage,"length":_vm.total,"currentPage":_vm.currentPage},on:{"changeCurrentPage":_vm.changeCurrentPageHandler,"changeItemsPerPage":_vm.changeItemsPerPageHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }