<template>
  <div>
    <a-spin :spinning="this.$store.getters.getLoadPets">
      <div class="top">
        <div class="caption">{{ $route.name }}</div>
        <!--        <a-->
        <!--          class="add_object open-popup-link"-->
        <!--          href="#add_object"-->
        <!--          data-effect="mfp-move-horizontal"-->
        <!--          @click.prevent="addObject"-->
        <!--        >-->
        <!--          Добавить QR-code-->
        <!--        </a>-->
      </div>
      <div class="middle">
        <form class="filter-form">
          <label for="phone" class="field-filter"  style="width: 300px">
            <div style="width: 100%">
              <input
                name="phone"
                v-model="form.phone"
                class="input_default-filter"
                type="text"
                placeholder="Телефон"
                @input="filterFormChange"
              />
            </div>
          </label>
          <label for="uuid" class="field-filter" style="width: 300px">
            <div style="width: 100%">
              <input
                  name="uuid"
                  v-model="form.uuid"
                  class="input_default-filter"
                  type="text"
                  placeholder="UUID"
                  @input="filterFormChange"
              />
            </div>
          </label>
        </form>
        <div class="full_objects">
          <UkTable
            :columns="columns"
            :data="computedData"
            :currentPage="this.$store.getters.getPagePets"
            :itemsPerPage="this.$store.getters.getItemsPerPage"
            :total="this.$store.getters.getTotalPets"
            @changeItemsPerPageHandler="changeItemsPerPageHandler"
            @changeCurrentPageHandler="changeCurrentPageHandler"
          >
            <div class="uk-table-slot">
              <div>Локаций пока нет</div>
              <!--              <a @click.prevent="addObject">Добавить qr-code</a>-->
            </div>
          </UkTable>
        </div>
      </div>
      <Drawer :showDrawer="showDrawer" :close="closeDrawer" :maxWidth="888">
        <EditObject :cardData="cardData" />
      </Drawer>
    </a-spin>
  </div>
</template>

<script>
import "moment/locale/ru";
import { cloneDeep } from "lodash";
import { notification } from "ant-design-vue";

import { bus } from "@/bus";

import UkTable from "@/components/4laps/UkTable";
import UkTableRowItemDate from "@/components/4laps/UkTableRowItemDate";
import UkTableRowItemQR from "@/components/4laps/UkTableRowItemQR";
import UkTableRowItemEdit from "@/components/4laps/UkTableRowItemEdit";
import UkTableRowItemPhone from "@/components/4laps/UkTableRowItemPhone";
import UkTableRowItemLocations from "@/components/4laps/UkTableRowItemLocations";

import Drawer from "@/components/4laps/Drawer";
import EditObject from "@/components/4laps/EditObject";

const columns = [
  {
    name: "QR",
    classSuffix: "qr",
    id: "qr",
    component: UkTableRowItemQR,
  },
  {
    name: "Имя хозяина",
    classSuffix: "owner_name",
    id: "owner_name",
    component: UkTableRowItemPhone,
    // filter: true,
  },
  {
    name: "Кличка животного",
    classSuffix: "pet_name",
    id: "pet_name",
    component: UkTableRowItemPhone,
  },
  {
    name: "Телефон",
    classSuffix: "phone",
    id: "phone",
    component: UkTableRowItemPhone,
  },
  {
    name: "Дополнительный контакт: Телефон",
    classSuffix: "phone_2",
    id: "phone_2",
    component: UkTableRowItemPhone,
  },
  {
    name: "Дополнительный контакт: Имя ",
    classSuffix: "dop_name",
    id: "dop_name",
    component: UkTableRowItemPhone,
  },
  {
    name: "СМС",
    classSuffix: "sms_code",
    id: "sms_code",
    component: UkTableRowItemPhone,
  },
  {
    name: "Локации",
    classSuffix: "locations",
    id: "locations",
    component: UkTableRowItemLocations,
  },
  {
    name: "Дата создания",
    classSuffix: "date",
    id: "date",
    component: UkTableRowItemDate,
  },
  {
    name: "Редактирование",
    classSuffix: "edit",
    id: "edit",
    component: UkTableRowItemEdit,
  },
];

export default {
  name: "ListOfObjects",
  data() {
    return {
      columns,
      showDrawer: false,
      cardData: {},
      form: {
        phone: "",
        uuid: "",
      },
    };
  },
  components: {
    UkTable,
    Drawer,
    EditObject,
  },
  methods: {
    addObject: function () {
      this.cardData = {};
      this.showDrawer = true;
    },
    closeDrawer: function () {
      this.showDrawer = false;
      this.cardData = {};
    },
    changeItemsPerPageHandler: function (n) {
      this.$store.commit("setItemsPerPage", n);
      this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
    },
    changeCurrentPageHandler: function (n) {
      this.$store.commit("setPagePets", n);
      this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
    },
    filterFormChange: function () {
      this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
    },
  },
  computed: {
    computedData: function () {
      function addEmitters(data) {
        data.forEach((item) => {
          item.edit = () => bus.$emit("showDrawer ListOfObjects", item);
        });
      }
      addEmitters(this.$store.getters.getPets);
      return cloneDeep(this.$store.getters.getPets);
    },
    queryOfFilters: function () {
      let query = ''
      if (this.form.phone) {
        query = '&phone=' + this.form.phone
      }
      if (this.form.uuid) {
        query += "&uuid=" + this.form.uuid;
      }
      return query
    }
  },
  created() {
    // bus.$on("showDrawer ListOfObjects", (cardData) => {
    //   this.showDrawer = true;
    //   this.cardData = {
    //     ...cardData,
    //     restaurant: cardData.restaurant.name,
    //   };
    // });
    bus.$on("ListOfObjects update after save", () => {
      // const mappedData = mappingB2F(newData);
      // const item = this.data.find((item) => item.key === mappedData.key);
      // if (item) Object.assign(item, mappedData);
      // bus.$emit("UkTable stay at page");
      notification.open({
        message: "Данные успешно сохранены",
      });
      this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
    });
    bus.$on("ListOfObjects update after delete", () => {
      // const mappedData = mappingB2F(newData);
      // const item = this.data.find((item) => item.key === mappedData.key);
      // if (item) Object.assign(item, mappedData);
      // bus.$emit("UkTable stay at page");
      notification.open({
        message: "Данные успешно удалены",
      });
      this.loading = true;
      this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
    });
    // bus.$on("ListOfObjects push and go to the last page", (newData) => {
    //   this.data.push(mappingB2F(newData));
    //   bus.$emit("UkTable go to last page");
    //   this.closeDrawer();
    // });
    // bus.$on("ListOfObjects update after delete", (newData) => {
    //   const mappedData = mappingB2F(newData);
    //   const indexToDelete = this.data.findIndex(
    //     (item) => item.key === mappedData.key
    //   );
    //   this.data.splice(indexToDelete, 1);
    //   if (indexToDelete === this.data.length) {
    //     bus.$emit("UkTable go to last page");
    //   } else {
    //     bus.$emit("UkTable stay at page");
    //   }
    //   this.closeDrawer();
    // });
  },
  mounted: function () {
    this.$store.dispatch("getPetsActions", this.$store.getters.getQuery + this.queryOfFilters);
  },
};
</script>

<style scoped>
.customTitle {
  color: var(--use-color-primary-700);
}

.component-header {
  display: flex;
  align-items: start;
}

.uk-table-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
}

.uk-table-slot a {
  text-decoration: underline;
}

.filter-form {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.field-filter{
  padding: 6px;
  border: 1px solid var(--use-color-primary-700);
  border-radius: 8px;
}
</style>
