<template>
  <div class="column" :class="'item_' + classSuffix">
    <div class="location-scan" v-if="data.length > 0" @click="showDrawerAction">
      {{ data.length }}
    </div>
    <div v-if="data.length === 0"></div>
    <Drawer :showDrawer="showDrawer" :close="closeDrawer" :maxWidth="888">
      <div class="location-title-wrapper">
        <div class="location-title">Cканирования QR-codes</div>
      </div>

      <div class="location-wrapper">
<!--        <yandex-map-->
<!--          style="height: 500px"-->
<!--          :coords="center"-->
<!--          :controls="[]"-->
<!--          :zoom="zoom"-->

<!--        >-->
<!--&lt;!&ndash;          @click="onClick"&ndash;&gt;-->
<!--          &lt;!&ndash;          <ymap-marker&ndash;&gt;-->
<!--          &lt;!&ndash;              marker-id="123"&ndash;&gt;-->
<!--          &lt;!&ndash;              :coords="coords"&ndash;&gt;-->
<!--          &lt;!&ndash;              :balloon-template="balloonTemplate"&ndash;&gt;-->
<!--          &lt;!&ndash;          ></ymap-marker>&ndash;&gt;-->

<!--          <ymap-marker-->
<!--            v-for="(group, index) in coords"-->
<!--            :key="index"-->
<!--            :coords="group"-->
<!--            marker-id="1"-->
<!--            marker-type="Polyline"-->
<!--            :marker-stroke="{-->
<!--              width: '5',-->
<!--              color: '#641eff',-->
<!--            }"-->
<!--          />-->
<!--        </yandex-map>-->

        <div
          v-for="item in data"
          :key="item.id"
          class="coordinates"
          :class="getNextCalssColor()"
        >
<!--          <a href="https://yandex.ru/maps/?ll=30.310182,59.951059&pt=30.335429,59.944869~30.34127,59.89173&z=12&l=map">Летний сад</a>-->
          <a class="coordinate" target="_blank" :href="`https://yandex.ru/maps/?ll=${item.lon},${item.lat}&pt=${item.lon},${item.lat}&z=12&l=map`">{{ item.created_at }}</a>


        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { random } from "lodash";
import Drawer from "@/components/4laps/Drawer.vue";
import {
  sortsCoords,
  getDistanceForCoords,
  getCoordsCenter,
  groupCoordsByTime,
} from "@/helpers/geoHelpers";
// Испортируем массив с размерами карты на различных зумах.
// В нем: coef - коэффициент зума. Значения min/max размеры карты,
// измеренные с помощью линейки самой карты.
const DIMENSIONS = require("@/assets/json/dimensions.json");

export default {
  name: "UkTableRowItemLocations",
  components: { Drawer },
  data() {
    return {
      classColors: ["pink", "blue", "green", "red", "purple", "violet"],
      lastColor: "",
      showDrawer: false,

      zoom: 8.5,
      center: [56, 39],
      coords: [],
      mapList: [
          {
            latitude: 44.04118666666667,
            longitude: 43.07887000000001,
            timestamp: 1629979606000
          },
          {
            latitude: 44.04118666666667,
            longitude: 43.07887000000001,
            timestamp: 1629979606000
          }

        ],
      mapCount: 10,
      mapTime: 881687074268570,
    };
  },
  props: ["data", "classSuffix"],
  mounted() {
    // Вызываем машстабирование и центрирование
    // данных, при рендере компонента
    this.scaling();
  },
  watch: {
    // Отслеживаем изменение mapTime, это ключ
    // последнего изменения массива mapList
    mapTime() {
      // Вызываем машстабирование и центрирование
      // данных, при апдейте массива координат передвижения ребенка.
      this.scaling();
    },
  },
  computed: {
    balloonTemplate() {
      return `
        <h1 class="red">Hi, everyone!</h1>
        <p>I am here: ${this.coords}</p>
        <img src="http://via.placeholder.com/350x150">
      `;
    },
  },
  methods: {
    getNextCalssColor() {
      const colorIndex = random(0, this.classColors.length - 1);
      return this.classColors[colorIndex];
    },
    showDrawerAction: function () {
      this.showDrawer = true;
    },
    closeDrawer: function () {
      this.showDrawer = false;
    },
    onClick(e) {
      this.coords = e.get("coords");
    },
    // Lt - latitude / Lg - longitude
    // Данный метод добавляет масштаб карте в зависимости от
    // расстояния между крайними точками по широте и долготе
    // и добавляет координаты в массив this.coords
    // Используется только при рендере компонента,
    // либо при изменении периода нахождения ребенка
    scaling() {
      if (!this.mapCount) return;

      // Сортируем координаты широты и долготы
      const resultLt = sortsCoords(this.mapList, "latitude");
      const resultLg = sortsCoords(this.mapList, "longitude");

      // Передаем эти координаты в метод который нам возращает
      // расстояние между координатами в метрах
      const argsLt = getDistanceForCoords(resultLt);
      const argsLg = getDistanceForCoords(resultLg);

      // Вычисляем центр для широты и долготы
      const ltCoordsCenter = getCoordsCenter(resultLt, "latitude");
      const lgCoordsCenter = getCoordsCenter(resultLg, "longitude");

      // Сравниваем широту или долготу и выбираем максимальное значение расстояния.
      // Если у нас с бэка пришел пустой массив с координатами, то метод  getDistanceForCoords возращает 0.
      // Таким образом если этот метод вернул нам 0, то здесь сравнение dimension.min * 1000 < max; выдаст
      // нам undefined, а это и вызовет ошибку здесь:
      const max = Math.max(argsLt, argsLg);

      // Находим элемент зума с тем же расстоянием,
      // умножаем на 1000 потому что параметры написаны в километрах,
      // а нам нужны метры.
      //
      // DIMENSIONS[0] - выводит первый элемент из этого массива,
      // если DIMENSIONS.find ничего не возвращает.
      // Это может произойти если у нас нет никаких координат
      const zoomCand =
        DIMENSIONS.find((el) => {
          return el.min * 1000 < max;
        }) || DIMENSIONS[0];

      // Определение zoom для мобильных устройств, нужно
      // для того чтобы координаты отображались в поле видимости карты.
      // Дело в том что окно карты на мобильных по высоте меньше чем на других девайсах
      // и координаты у нас отображаются в рамках размера карты десктопа,
      // и чтобы они отображались корректно на мобильных, мы уменьшаем значение zoom на 1
      // const zoomForDevices = this.$device.isMobile
      //   ? zoomCand.coef - 1
      //   : zoomCand.coef;
      const zoomForDevices = zoomCand.coef;
      // Добавляем найденный коэффициент в переменную zoom,
      // и в переменную center добавляем координаты центра по
      // ширине и долготе
      this.zoom = zoomForDevices;
      this.center = [ltCoordsCenter, lgCoordsCenter];

      // Обнуляем массив координат
      this.coords = [];

      // Формируем список точек для рендера.
      // Чтобы не засорять VUEX обрабатываем их здесь.
      // yandex-map получает массив координат, а у нас хрнаится
      // объект, поэтому мы и должны преобразовать данные.
      groupCoordsByTime(this.mapList).forEach((group, index) => {
        // Инициализируем группу
        this.coords[index] = [];
        // Добавляем в группу элементы
        group.forEach((coord) => {
          this.coords[index].push([coord.latitude, coord.longitude]);
        });
      });
    },
  },
};
</script>
<style>
.location-title-wrapper {
  position: relative;
  background: #fff;
  padding-top: 2.5rem;
  padding-left: 2.5rem;
  width: auto;
  text-align: left;
}
.location-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  padding-bottom: 2rem;
  margin-bottom: 2.1rem;
}
.coordinates{

}
.coordinate{

}
</style>
